
.catalog-main-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2.5rem;
    padding-bottom: 3rem;
  }
  

#catalog-container {
    flex-grow: 2;
    padding-bottom: 3rem;
    overflow-y: scroll;
    color: white;
  
    display: grid;
    grid-template-columns: repeat(auto-fill, 12rem); //minmax(12rem, 1fr)
    justify-content: space-evenly;
    align-content: start;
    grid-gap: 1rem;
    
  &.catalog-item-ex{
    grid-template-columns: repeat(auto-fill, 15rem);
  }
  }

  .catalog-item {
    width: 15rem;
    height: 3rem;
    align-items: center;
    background-color: #495057;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 1px #495057;

    .catalog-item-container{
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      .separator{
        margin: 0rem .5rem;
        width: 1px;
        background-color: #69717e;
        height: 80%;
      }
    }
  
    &:hover {
      box-shadow: 0px 0px 2px 4px #495057;
    }
  
    .catalog-item-description {
      color: #b0b4b7;
      font-size: 0.8rem;
    }
  
    .catalog-item-icon {
      font-size: 1.3rem;
      color: #b0b4b7;
      cursor: pointer;
  
      &:hover {
        color: white;
      }
  
      &.edit {
        font-size: 1.1rem;
        line-height: unset;
      }
    }
  
    .catalog-item-name {
      margin-left: 1.3rem;
    }
  
    .catalog-item-time {
      font-size: .6rem;
      letter-spacing: 2px;
      text-decoration: revert;
      color: #b0b4b7;
    }
  
    .catalog-item-preview {
      width: 124px;
      height: 124px;
  
      cursor: zoom-in;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
  
      &:hover {
        mask-image: radial-gradient(rgba(100, 100, 100, 1), transparent);
        -webkit-mask-image: radial-gradient(rgba(100, 100, 100, 1), transparent);
      }
    }
  
    .ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }