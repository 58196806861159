@import "../../../css/custom_variables.scss";
@import "bootstrap/scss/bootstrap.scss";

::-webkit-scrollbar-thumb {
  background: $blue;
}
::-webkit-scrollbar-thumb:hover {
  background: $dark-blue;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

#avatar-container {
  flex-grow: 2;
  color: white;
  
}

.sticky {
  position: sticky;
  top: 0;
}

#tab-catalog-editor {
  overflow-y: auto;
  margin: 10rem auto auto auto;
  .material-icons-outlined{
    line-height: unset;
  }
  #tab-catalog-panel {
    background-color: transparent;
    border: none;

    #tab-catalog-body {
      .tag-list-container {
        padding-top: 10rem;
      }
    }
  }
}

.badge {
  line-height: unset;
}

#tags-modal {
  .tag-el {
    &:hover {
      opacity: 0.9;
    }
    max-width: 10rem;
    
    color: whitesmoke;
    padding: 0.5rem 0.5rem 0.5rem 1rem;

    .separator {
      height: 1.5rem;
      width: 1px;
      background-color: #f5f5f56b;
    }

    span.tag-close {
      font-size: 1rem;
      cursor: pointer;
    }
  }

  .modal-body{
    .modal-overflow-limit{
      max-height: 30vh;
    }
  }

    .modal-footer {
      .error-icon{
        color: #bc1c1c;
      }
    }
  
  button.btn-primary{
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
}

// CONTAINER & CELLS

.explorer-container-root {
  height: 100%;

  nav {
    background-color: #3d444a;
    align-items: initial;
    top: 0;
    display: flex;
    z-index: 1;
    height: 4rem;
    padding: 0rem 1rem;

    #title-resource{
      color: white;
      letter-spacing: 1px;
    }

    .user-icon-container {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;

      .menu-icon{
        font-size: 2rem;
        color: whitesmoke;
      }

      .user-icon {
        background-position: center;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background-size: cover; 
      }
    }
  }

  .explorerContainer {
    min-height: 100%;
    //position: absolute;
    //top: 10vh;
    //bottom: 10vh;
    //left: 20vw;
    //right: 20vw;
    display: grid;
    //grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-template-columns: repeat(auto-fill, 12rem);
    justify-content: space-evenly;
    //background: dimgray;
    padding: 1rem;
    border-radius: $rad * 2;
    box-shadow: inset;
    align-content: start;
    //grid-gap: 15px 0;
    grid-gap: 1rem;
    height: 75vh;
    overflow-y: scroll;
    .newSceneButton {
      max-width: 75%;
      max-height: 75%;
      width: 75px;
      height: 75px;
      // padding: calc(50% - 75px / 2);
      background: -webkit-linear-gradient(
        45deg,
        #272edc,
        #007bfe 30%,
        #21d0e1 90%
      );
      position: relative;
      left: 50%;
      top: 25%;
      transform: translate(-50%, -50%);
      background: linear-gradient(45deg, #272edc, #007bfe 30%, #21d0e1 90%);
      border-radius: 50%;
      &::before {
        content: "";
        background: whitesmoke;
        width: 50%;
        height: 10px;
        display: block;
        position: relative;
        transform: translate(0, -50%);
        top: 50%;
        left: 25%;
        border-radius: 2.5px;
      }

      &::after {
        content: "";
        background: whitesmoke;
        width: 10px;
        height: 50%;
        display: block;
        position: relative;
        border-radius: 2.5px;
        transform: translate(-50%, -25%);
        top: 25%;
        left: 50%;
      }
    }
    & > .explorerCell {
      //min-width: 150px;
      min-height: 12rem;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: space-evenly;
      color: whitesmoke;
      position: relative;
      border-radius: 5px;
      transition: all 100ms ease-in-out;
      box-shadow: rgb(0 0 0 / 30%) 0px 0px 10px;
      overflow: hidden;
      & > span {
        background-color: #1f1f20d1;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 9px;
        border-radius: 0 0 $rad $rad;
        overflow: hidden;
      }

      & > img {
        //background: url("https://nx-staging.vertx.cloud/core/FixedIcons/SceneIcon@2x.png");
        background-origin: 50% 50%;
        background-size: cover;
        width: 100%;
        height: 100%;
        border-radius: $rad;
        border: none;
      }
    }
  }
}

.text-gradient {
  display: inline-block;
  background: -webkit-linear-gradient(45deg, #272edc, #007bfe 30%, #21d0e1 90%);
  background: linear-gradient(45deg, #272edc, #007bfe 30%, #21d0e1 90%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.welcomeContainer {
  color: whitesmoke;
  display: flex;
  //background-image: url("/img/bg-nodes.png");
  background-size: cover;
  background-position: center;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  & > .welcomeContent {
    flex-direction: column;
    align-content: center;
    justify-content: center;
    display: flex;
    height: auto;

    & > h1 {
      font-weight: 100;
      font-size: 4.5rem;
      text-align: center;
    }
  }
}

.text-gradient {
  display: inline-block;
  background: -webkit-linear-gradient(45deg, #272edc, #007bfe 30%, #21d0e1 90%);
  background: linear-gradient(45deg, #272edc, #007bfe 30%, #21d0e1 90%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.welcomeContainer {
  color: whitesmoke;
  display: flex;
  //background-image: url("/img/bg-nodes.png");
  background-size: cover;
  background-position: center;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  & > .welcomeContent {
    flex-direction: column;
    align-content: center;
    justify-content: center;
    display: flex;
    height: auto;

    & > h1 {
      font-weight: 100;
      font-size: 4.5rem;
      text-align: center;
    }
  }
}

// Resource Context Menu
#resource-context {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 0px;
  border-radius: 0px;
  box-shadow: rgb(0 0 0 / 40%) 0px 0px 20px;
  position: absolute;
  z-index: 100;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.right-click-button {
  margin: 0px;
  border-radius: 0px;
}

// New Resource Form

.new-resource-container {
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
  position: fixed;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
}

.new-resource-panel {
  height: fit-content;
  width: fit-content;
  max-width: 95vw;
  padding-top: 3rem;
  padding-right: 3rem;
  padding-left: 3rem;
  padding-bottom: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #757e8c;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 20px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.hero-button-container {
  display: flex;
  justify-content: space-evenly;
}

.hero-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  //margin-right:10px;
  padding: 1rem;
  border: 2px solid #757e8b;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-button:hover {
  border: 2px solid rgba(255, 255, 255, 0.5);
}

.hero-button.active-select {
  border: 2px solid rgba(255, 255, 255, 1);
}

.hero-title {
  margin-bottom: 0px;
}

.hero-icon {
  width: 25px;
  margin-bottom: 5px;
}

h3.new-resource-title {
  color: white;
}

p.new-resource-description {
  color: rgba(255, 255, 255, 0.6);
}

h3.hero-title {
  color: white;
  text-align: center;
}

p.hero-description {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  white-space: nowrap;
}

.form-container {
  display: flex;
  margin-bottom: 10px;
}

label.resource-info {
  width: 15%;
  letter-spacing: 3px;
  color: white;
  font-size: 0.8rem;
}

input.resource-input {
  width: 85%;
  border-radius: 5px;
  border: none;
}

label.asset-type {
  width: 50%;
  margin-right: 10px;
}

input.asset-radio {
  position: fixed;
  opacity: 0;
}

.hero-tick {
  background: white;
  width: 20%;
  height: auto;
  border-radius: 105px;
  padding: 5px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.btn-dark {
  background: $dark-grey;
}

.panel-default {
  opacity: 0;
  transform: translateX(-50px);
  transition: all 0.3s ease-in-out;
}

.panel-animate {
  opacity: 1;
  transform: translateX(0px);
  transition: all 0.3s ease-in-out;
}

.new-resource-tag-area {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 3rem;
  max-width: 44rem;
  background: rgba(0, 0, 0, 0.1);
  padding: 5px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 3px;
  box-shadow: inset 0px 0px 5px rgb(0 0 0 / 30%);
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  margin-bottom: 0px;
  margin-left: 10px;
  margin-right: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  // position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $dark-text;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  // position: absolute;
  content: "";
  height: 24px;
  width: 26px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.custom-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $dark-text;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom-slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 26px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .custom-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.custom-slider.round {
  border-radius: 34px;
}

.custom-slider.round:before {
  border-radius: 50%;
}


.switch-text {
  margin-bottom: 0px;
  color: white;
}

body {
  background: $light-bg;
  background-image: url("/img/grid-bg.svg");
}

.top-btn-container {
  margin-bottom: 1rem;
}

.app-title {
  font-size: 4rem;
  color: $white;
}
.app-subtitle {
  font-size: 1.2rem;
  color: $lightgrey-text;
  margin-bottom: 3rem;
}
.app-subtext {
  font-size: 1rem;
}

.homepage-context {
  align-items: flex-start;
  display: block;
  opacity: 1;
  height: 100%;
}

.homepage-selection {
  display: flex;
  padding: 8rem;
  justify-content: center;
  align-items: center;
}

#explorer-container {
  flex-grow: 2;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  height: 100%;
  display: block;
  overflow: hidden;
}

#tab-catalog-editor {
  flex-grow: 2;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  height: 100%;
  overflow: hidden;
}

.explorer-panel {
  // background: $white-70;
  height: 100%;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  // overflow-y: scroll;
}

@keyframes cell-spawn {
  0% {
    opacity: 0;
    transform: scale(0.9) rotateY(-45deg) rotateX(5deg);
  }
  50% {
    opacity: 1;
    transform: rotateY(0deg) rotateX(0deg);
  }
  100% {
    transform: scale(1) rotateY(0);
  }
}

.asset-fade {
  opacity: 1 !important;
  transform: rotateY(0deg) rotateX(0deg) !important;
  animation: cell-spawn 0.8s;
}

.cell-initial-state {
  opacity: 0;
  transform: rotateY(-45deg) rotateX(5deg);
}

.loading-img {
  width: 3%;
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 3rem;

  .dropdown-toggle {
      min-width: 8rem;

      &[aria-expanded="true"]::after {
        transform: rotate(180deg);
      }
    
      &::after {
        margin-left: 0.555em;
        vertical-align: 0.155em;
      }
    }
  }

.search-bar-container {
  display: flex;
  margin-right: 2rem;
  flex-wrap: wrap;
  margin-left: 2rem;
  justify-content: center;

  .slider:not(.active-switch) {
    background-color: #69717e;
  }

  .toggle {
    border:none;

    &:not(.active-toggle){
      background-color: #535962;
      
      &:not(:hover) {
          background-color: #69717e;
      }
    }
  }
}

.search-bar {
  min-width: 20rem;
  width: 30vw;
  height: auto;
  background: none;
  display: flex;
  align-items: center;
  border-radius: 10rem;
  // padding: 0.3rem;
  // border: 1px solid rgba(255,255,255,0.5);
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset rgba(0 0 0 / 5%) 0px 0px 10px;

  button {
    border-radius: 10rem 0rem 0rem 10rem;
  }
}

.block-pointer {
  pointer-events: none;
}

.search-bar-icon {
  height: unset;
  margin-left: 1rem;
  color: white;
}

.search-text {
  margin-left: 0.5rem;
  background: none;
  border: none;
  width: 100%;
  color: white;
}

.search-text:focus {
  outline: none;
}

.search-text::placeholder {
  color: white;
}

.toggle-container {
  display: none;
}

.toggle {
  border-radius: 0px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  margin: 0px;
  display: flex;
  justify-content: center;
  width: inherit;
  padding: 5px;
  width: 100px;
  background: $dark-text;
  cursor: pointer;
  color: white;
}

.active-toggle {
  background: $primary;
  pointer-events: none;
  user-select: none;
}

.active-switch {
  background: $primary;
}

.disable {
  user-select: none;
  pointer-events: none;
  opacity: 0.3;
}

.new-resource-button {
  white-space: nowrap;
  border-radius: 100px;
  border: none;
  border-radius: 50px !important;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100px;

  span {
    line-height: unset;
  }
}

.new-resource-dropdown {
  margin-top: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 10px;
}

.new-resource-dropdown-item {
  cursor: pointer;
  text-align: center;
  padding: 0.4rem;
}

.new-resource-dropdown-item:hover {
  //font-weight: 600;
  background: $light-blue;
  border-radius: 10px;
}

.new-resource-dropdown-item:first-child {
  border-radius: 8px 8px 0px 0px;
}

.new-resource-dropdown-item:last-child {
  border-radius: 0px 0px 8px 8px;
}

.switch-group {
  display: flex;
  align-items: center;
}

.switch-left {
  border-radius: 50px 0px 0px 50px;
}

.switch-right {
  border-radius: 0px 50px 50px 0px;
}

.switch-description {
  white-space: nowrap;
  color: white;
}

.new-resourceCell {
  box-shadow: inset rgb(0 0 0 / 20%) 0px 0px 15px !important;
}
.tag-section-status {
  margin-right: 0.5rem;
  transition-duration: 0.5s;
  transition-property: transform;
  transform: rotate(90deg);
}

.tag-section:has(.d-none) {
  .tag-section-status{
    transition-property: transform;
    transform: rotate(0deg);
  }
}
.tag-title {
  color: white;
  cursor: pointer;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 1rem;
  display: flex;
}

.tag-title:hover {
  background: rgba(255, 255, 255, 0.1);
}

.view-mode-button {
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 5px 15px;
}

.view-mode-left {
  border-radius: 50px 0px 0px 50px;
  padding: 5px 10px 5px 15px;
}

.view-mode-right {
  border-radius: 0px 50px 50px 0px;
  padding: 5px 15px 5px 10px;
}

.view-mode-icon {
  height: 23px;
}

// Edit Resource Panel

.edit-resource-panel {
  position: sticky;
  top: 0;
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  height: 80vh;
  background: rgba(0, 0, 0, 0.1);
}

.info-wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
  //padding:0.5rem 0.8rem;
}

.info-label {
  width: 50px;
  color: white;
  font-size: 0.9rem;
  font-weight: 600;
  white-space: nowrap;
}

.name-input {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  flex-grow: 2;
  font-size: 0.9rem;
  padding: 5px 10px;
  color: #495057;
  font-weight: 600;
  overflow: hidden;
  flex: 1 1 50px;
}

.tags-area-title {
  color: white;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
}

.tag-input {
  border-radius: 5px 0px 0px 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  flex-grow: 2;
  font-size: 0.9rem;
  padding: 5px 10px;
  width: 100px;
}

.tag-input::placeholder {
  color: white;
}

p.no-tags-available {
  color: rgba(255, 255, 255, 0.6);
}

.input-disabled {
  background: none;
  border: none;
  padding-left: 0px !important;
  color: #495057bf;
}

.edit-button {
  width: 60px;
  margin: 0px 0px 0px 5px;
  float: right;
}

.selected-cell {
  transform: scale(1.2) !important;
  z-index: 10;
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 15px;
  transition: scale 0.5s ease-in-out;
  box-shadow: inset 0 0 10px 0 #0e73e6, 0 0 20px 0 #1c76dc !important;
  border: 1px solid #80b7f7 !important;
}

.close-button {
  width: 15px;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  filter: brightness(0.4);
}

.canvas-parent{
  z-index:2;
  position: absolute;
  width: 100%;
  height: 100%;
}

// Card Primary Panels - Main //

.container-overlay-left {
  margin: 1rem;
  
}
.container-overlay-right {
  margin: 1rem;
}

.card-header {
  padding: 0rem;
  border-bottom: none;
  background-color: $black-700;
}

.w-100 {
  width: 100%;
}

// Card Primary Panels - Top Nav //

.nav-tabs {
  border-bottom: 1px solid $secondary-10;
}
.nav-tabs .nav-link {
  border: none;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
  user-select: none;
}

.nav-link {
  padding: 0.7rem 1.5rem;
  font-size: 1rem;
  font-weight: 700;
  color: $secondary-70;
  cursor: pointer;
}

.tab-icon {
  width: 80px;
  height: auto;
}

.tab-icon:hover {
  background: rgba(0, 0, 0, 0.1);
}

.nav-link.active {
  border-bottom: 1px solid $blue;
}
.nav-link.active:hover {
  border-bottom: 1px solid $blue;
}

.nav-icon {
  width: 100%;
}

// Card Primary Panels - Body //

.card-text {
  padding: 0.5rem 0.8rem;
  font-size: 1rem;
  font-weight: 700;
  background: $white;
  color: $dark-text;
  border-bottom: 1px solid $secondary-20;
  margin-bottom: 0px;
  flex-grow: 2;
}

.editor-body {
  background: $editor-body;
}

// Model Panel //

// Resource Info & Tags

.info-wrapper {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.8rem;
}

.material-info-wrapper {
  display: flex;
  align-items: center;
  padding: 0.1rem 0.1rem;
}

.custom-info-wrapper {
  background: rgba(0, 0, 0, 0.1);
  padding: 6px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.info-wrapper:nth-child(2) {
  padding-bottom: 0.25rem !important;
}

.info-wrapper:last-child {
  padding-top: 0.25rem !important;
}

.info-label {
  width: 50px;
  color: #646c77;
  font-size: 0.9rem;
  font-weight: 600;
  white-space: nowrap;
}

.stats-info-label {
  width: 7.5rem;
  color: #646c77;
  font-size: 0.9rem;
  font-weight: 600;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}

.stats-info-wrapper{
  display: flex;
  align-items: center;
  padding: 0.25rem 0.8rem;
}

.name-input {
  border-radius: 5px 0px 0px 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  flex-grow: 2;
  font-size: 0.9rem;
  padding: 5px 10px;
  height: 2.4rem;
}

.input-disabled {
  background: none;
  border: none;
  padding-left: 0px !important;
}

.edit-button {
  width: 60px;
  margin: 0px 0px 0px 0px;
  border-radius: 0px 5px 5px 0px;
}

// List Items

// File List

.scrollable-list-group {
  padding: 0;
  margin: 0;
}

.dropzone-drag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10vmin;
  align-content: center;
  border: dashed;
  color: lightgrey;
}

.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10vmin;
  align-content: center;
  color: grey;
  cursor: pointer;
  box-shadow: inset 0 0 1vmin #ccc;
}

.dropzone-small {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 4rem;
  align-content: center;
  color: grey;
  cursor: pointer;
  box-shadow: inset 0 0 1vmin #ccc;
  word-break: break-word;
  text-align: center;
}

.list-group-item {
  padding: 0.5rem;
  display: flex;
}

.material-list-group-item {
  padding: 0rem;
}

.list-info-container {
  float: left;
  flex-grow: 4;
  display: flex;
  align-items: center;
  padding-left: 10px;
  /* max-width: 60%; */
  overflow: hidden;
  //margin-right: 10px;
  width: 100%;
  padding: 6px;
}

.list-group-item-dark {
  background: $white;
  border-radius: 3px;
  margin: 6px;
  margin-left: 0.3rem;
  color: #646c77;
  font-size: 0.9rem;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 1px 1px;
  border: none;
  cursor: pointer;
}

.list-title {
  font-size: 0.9rem;
  line-height: 0px;
  padding-left: 5px;
}

.list-group-item-dark:hover {
  background: rgba(14, 115, 230, 0.1);
}

.list-item:hover {
  background: rgba(14, 115, 230, 0.2);
}

.selected-list-item {
  background: #0e73e6 !important;
  color: white;
}

.selected-list-item > img {
  filter: brightness(10);
}

.button-container {
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  flex: 1;
}

.icon-button {
  display: flex;
  align-items: center;
}

.button-icon {
  width: 16px;
}

.tag-suggestion-area {
  flex-wrap: wrap;
}

.tag-area {
  width: 60%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  overflow-x: scroll;
}

.tag {
  padding: 0px 7px 0px 7px !important;
  margin: 6px 3px;
  background: springgreen;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.tag:first-child {
  margin-left: 6px;
}

.remove-button-wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-line-1 {
  background: rgba(0, 0, 0, 0.8);
  height: 2px;
  width: 13px;
  transform: translateX(10px) rotate(45deg);
}

.close-line-2 {
  background: rgba(0, 0, 0, 0.8);
  height: 2px;
  width: 13px;
  transform: rotate(-45deg);
}

#save-tags {
  border-radius: 0px 5px 5px 0px;
  height: 100%;
}

.resource-filter-buttons {
  display: none;
}

.resource-toggle-dropdown > .btn {
  border-radius: 0rem 100px 100px 0rem !important;
}

.save-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
}

.invalid-form-control {
  input:invalid{
    border-color: red;
  }
}

.h-share-qr-popup-link{
  overflow: hidden;
  outline: none;
  resize: none;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: solid 1px var(--gray);
  padding: 0.2em;
  border-radius: 0.2em;
  transition: background-color 0.3s;
}

.h-share-qr-popup-link:active {
  background-color: #acb9b9;
  transition: background-color 0s;
  color: white;
}

@include media-breakpoint-up(md) {
  .new-resource-panel {
    max-width: 95vw;
  }
  .hero-button {
    padding: 2rem;
  }
}

@include media-breakpoint-up(lg) {
  .resource-toggle-dropdown {
    display: none;
  }
  .toggle-container {
    display: flex;
  }
  .new-resource-panel {
    max-width: 80vw;
  }
  .hero-button {
    padding: 4rem;
  }
}
