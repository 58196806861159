$main-color: whitesmoke;
$top-bar-color: #3d444a;
$border-item-color: #353535;
$background-item-color: #495057;
$background-item-color-hover: #646e77;

.side-bar {
    height: 100%;
    width: 4rem;
    //position: fixed;
    //transform: translate(-100%, 0);
    z-index: 10;
    top: 0;
    left: 0;
    background-color: $background-item-color;
    overflow-x: hidden;
    transition: .5s;
    display: flex;
    flex-direction: column;
    transition: .5s;
    user-select: none;

    .overlay-menu {
        z-index: 10;
        position: fixed;
        background-color: #3d444a;
        width: 15rem;

        .header-menu {
            padding: 0.5rem;
            font-size: 1.2rem;
        }

        .sub-list {
            transform: translateX(0%);

            li {
                padding-left: 1rem !important;
            }
        }
    }

    .sub-list {
        border: none;
        transform: translateX(30%);
        transition: transform .5s;
    }

    .ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .user-profile-info {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &.open {
        //transform: translate(0%, 0);
        width: 20rem;

        .sub-list {
            transform: translateX(0%);
        }

        .sidebar-footer {
            .signout-block {
                > span {
                    display: initial;
                }
            }
        }
    }

    .sidebar-close-container {
        background-color: $top-bar-color;
        display: grid;
        grid-template-columns: 1fr auto;
        height: 4rem;
        padding: 0rem 0.6rem;

        .user-info {
            overflow: auto;
            color: $main-color;
            display: flex;
            flex-wrap: nowrap;
            border: none;
            //padding: 0.75rem 0.75rem;
            align-items: center;
            width: 100%;
            flex-grow: 1;

            .user-icon {
                flex: none;
                margin: 0rem 1rem 0 0rem;
                background-position: center;
                width: 40px;
                height: 40px;
                border-radius: 20px;
                cursor: pointer;
                background-size: cover;
            }
        }
    }

    button.close {
        font-weight: normal;
        text-shadow: none;
        font-size: 2rem;
    }

    .sidebar-list-ul {
        flex-grow: 1;
        flex-basis: 0;
        overflow-y: auto;
        padding-right: .2rem;

        .sidebar-item {
            border: none;
            display: grid;
            padding: 0;
            color: $main-color;
            font-weight: bold;
            background-color: transparent;
            border-left: none;
            border-right: none;

            &:not(:first-child) > div:not(.overlay-menu) {
                margin-top: 0.5rem !important;
            }

            > div:not(.overlay-menu) {
                cursor: pointer;
                margin-top: 0;
                padding: 0.75rem 0.75rem 0.75rem 0.5rem;

                &:hover:not(.overlay-menu), &.active:not(.overlay-menu) {
                    background-color: $background-item-color-hover;
                }
            }

            .icon {
            }

            .icon-open {
                color: #5e5e5e;

                &:hover {
                    color: #7e7e7e;
                }
            }

            &:first-child {
                border-top: none;
            }

            .sub-list {
                li {
                    cursor: pointer;
                    color: $main-color;
                    padding-left: 3rem;
                    border-radius: 0rem;
                    background-color: $background-item-color;
                    border: none;


                    &:hover, &.active {
                        background-color: $background-item-color-hover;
                    }

                    .title {
                        font-size: 1rem;
                    }

                    .icon {
                        font-size: 1.5rem;
                    }
                }
            }

            .title {
                flex-grow: 1;
                flex-basis: 0;
                font-size: 1.1rem;
                letter-spacing: 1px;
                font-weight: 400;
            }

            .icon {
                font-size: 2rem;
            }

            .icon-open {
                font-size: 2.2rem;
            }
        }
    }

    span.text-style {
        font-size: .8rem;
        letter-spacing: 2px;
    }

    .sidebar-footer {
        .signout-block {
            width: 100%;
            color: $main-color;
            background-color: #171717;
            display: flex;
            //grid-template-columns: 1fr auto;
            column-gap: 0.5rem;
            flex-wrap: nowrap;
            border: none;
            padding: 0.75rem 0.75rem;
            align-items: center;
            justify-content: space-around;
            justify-items: end;

            > span {
                display: none;
            }

            .ellipsis.text-style {
                margin-left: auto;
            }

            .button-logout {
                font-size: 0.7rem;
                letter-spacing: 2px;
                padding: 0.2rem;
                width: 1.8rem;
                height: 1.5rem;

                &:hover {
                    border-color: #c83030;
                }

                .logout {
                    font-size: 1rem;
                }
            }
        }
    }
}