@import "../css/custom_variables.scss";
@import "bootstrap/scss/bootstrap.scss";

$rad: 3.5px;

.explorerContainer {
    min-height:100%;
    overflow-x:hidden;
    //position: absolute;
    //top: 10vh;
    //bottom: 10vh;
    //left: 20vw;
    //right: 20vw;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    justify-content: space-evenly;
    //background: dimgray;
    padding: 3px;
    border-radius: $rad * 2;
    box-shadow: inset;
    align-content: start;
    //grid-gap: 15px 0;
    grid-gap:3px;
    .newSceneButton {
      max-width: 75%;
      max-height: 75%;
      width: 75px;
      height: 75px;
      // padding: calc(50% - 75px / 2);
      background: -webkit-linear-gradient(
        45deg,
        #272edc,
        #007bfe 30%,
        #21d0e1 90%
      );
      position: relative;
      left: 50%;
      top: 25%;
      transform: translate(-50%, -50%);
      background: linear-gradient(45deg, #272edc, #007bfe 30%, #21d0e1 90%);
      border-radius: 50%;
      &::before {
        content: "";
        background: whitesmoke;
        width: 50%;
        height: 10px;
        display: block;
        position: relative;
        transform: translate(0, -50%);
        top: 50%;
        left: 25%;
        border-radius: 2.5px;
      }
  
      &::after {
        content: "";
        background: whitesmoke;
        width: 10px;
        height: 50%;
        display: block;
        position: relative;
        border-radius: 2.5px;
        transform: translate(-50%, -25%);
        top: 25%;
        left: 50%;
      }
    }
    & > .explorerCell {
      //min-width: 150px;
      min-height: 100px;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: space-evenly;
      color: whitesmoke;
      position: relative;
      border-radius: $rad;
      user-select: none;
      transition: all 100ms ease-in-out;
      border-radius: 5px;
  
  
      & > span {
        background-color: rgba(0,0,0,0.7);
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 9px;
        border-radius: 0 0 $rad $rad;
        font-size: 0.7rem;
        white-space: nowrap;
      }
      & > img {
        //background: url("https://nx-staging.vertx.cloud/core/FixedIcons/SceneIcon@2x.png");
        background-origin: 50% 50%;
        background-size: cover;
        width: 100%;
        height: 100%;
        border-radius: $rad;
        border: none;
        //z-index: -1;
        -webkit-transition: opacity 1s ease-in-out;
        -moz-transition: opacity 1s ease-in-out;
        -o-transition: opacity 1s ease-in-out;
        transition: opacity 1s ease-in-out;
      }
    }

    .explorerCell {
            //min-width: 150px;
            min-height: 100px;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: space-evenly;
            color: whitesmoke;
            position: relative;
            border-radius: $rad;
            transition: all 100ms ease-in-out;

    }

  }

  .cell-info-wrapper {
    z-index: 1;
  }

  .explorerCell {
    background-image: url(/img/placeholder_thumb.jpg);
    background-color: #a6afbd;
    background-size:cover;
  }
  .explorerCell:has(.space-border) {
    background-image: url(/img/space_thumb.jpg);
    }
    .explorerCell:has(.model-border) {
      background-image: url(/img/model_thumb.jpg);
      }
      .explorerCell:has(.media-border) {
        background-image: url(/img/media_thumb.jpg);
        }
        .explorerCell:has(.room-border) {
          background-image: url(/img/room_thumb.jpg);
          }
          .explorerCell:has(.deck-border) {
            background-image: url(/img/deck_thumb.jpg);
          }
          .explorerCell:has(.base-border) {
            background-image: url(/img/base_thumb.jpg);
          }
  .explorerCell:has(.div-loading) {
    animation-name: div-loading-animation !important;
    animation-duration: 1s !important;
    animation-iteration-count: infinite !important;
  }
  .explorerCell:has(.div-loaded) {
    animation-name: none;
  }
// FURTHER CELL STYLING

.cell-img {background-size:cover !important;}
.div-loading {
  pointer-events: none;
  animation-name: div-loading-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.div-loaded {
  animation-name: div-loaded-animation;
  animation-duration: 1s;
  transition: transform 1s ease-in-out;
}

//Show resource type after 1s of mouse over 
//.div-loaded:hover {
  //  rotate: 30deg;
  //  translate: 18% 31%;
  //opacity: 0;
  //transition-delay: 1s !important;
//}

@keyframes div-loading-animation {
  0% {
      filter: brightness(0.8);
  }

  50% {
      filter: brightness(1);
  }

  100% {
      filter: brightness(0.8);
  }
}

@keyframes div-loaded-animation {
  0% {
      opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cell-tab {
  position: absolute;
  top: 10px;
  right: 0;
  background: black;
  padding: 0px 10px;
  font-size: 0.9rem;
  border-radius: 50px 0px 0px 50px;
  transition: all 150ms ease-in-out;
}

.cell-tab-low {
  position: absolute;
  top: 40px;
  right: 0;
  padding: 0px 10px;
  font-size: 0.9rem;
  border-radius: 50px 0px 0px 50px;
  transition: all 150ms ease-in-out;
}

.published-resource-icon {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  margin: 5px;

}

@keyframes slowClap {
  0% {opacity:0.2};
  50% {opacity:1};
  100% {opacity:0.2};
}

.slow-clap {
  animation: slowClap 2s infinite;
}

.cell-tab-gray{
  background: gray;
}

.cell-tab-green{
  background: $success;
}

.cell-tab-red{
  background: $danger;
}

.hide-tab {
  opacity:0%;
  transform: translateX(100%);
}

.show-tab {
  opacity:100%;
  transform: translateX(0%);
}

.hide-tab-panel {
  opacity:0%;
}

.show-tab-panel {
  opacity:100%;
}

.cell-text {
  font-size: 0.9rem;
  transition: all 400ms ease-in-out;
  word-break: break-all;
  overflow:hidden;
}

.model-border {
  border-top:0.3rem solid $primary;
}

.space-border {
  border-top:0.3rem solid $yellow;
}

.media-border {
  border-top:0.3rem solid $purple;
}

.base-border {
  border-top:0.3rem solid $red;
}

.deck-border {
  border-top:0.3rem solid $orange;
}

.room-border {
  border-top:0.3rem solid $green;
}


  
// MINI RESOURCE EXPLORER - SEARCH BAR

.search-bar {
  width:min-content;
  min-width:200px;
  height: auto;
  background: none;
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 0px;
  // border: 1px solid rgba(255,255,255,0.5);
  background: rgba(0,0,0,0.4);
  box-shadow: inset rgba(0 0 0 / 5%) 0px 0px 10px;
}

.search-bar-icon {
  height:0.8rem;
  width:auto;
  margin-left:1rem;
}

.search-text {
  margin-left:0.5rem;
  background:none;
  border:none;
  width:100%;
  color:white;
}

.search-text:focus {
  outline:none;
}

.search-text::placeholder {
  color:white;
}

.upload-title {
  margin:0.7rem 0.5rem;
  font-weight: bolder;
}

.convert-file-button {
  width:fit-content !important;
}

.uploaded-file-text {
  flex-grow:2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.file-list {
  align-items: center;
}

.tagExplorerContainer {
  height:fit-content !important;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  justify-content: space-evenly;
  padding: 15px;
  border-radius: 7px;
  box-shadow: inset;
  align-content: start;
  grid-gap: 5px;
}

.right-click-menu {
  width: max-content;
}