.qr-preview-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  transition: all 0.3s;
  color: #fff;
  will-change: transform;
  user-select: none;
  -webkit-animation: show 0.5s forwards;
  animation: show .5s forwards;
  display: flex;
  flex-direction: column;

  .qr-preview-header {
    height: 60px;
    background: #000000bf;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem 0 1rem;
    position: relative;
    z-index: 90;
    animation: opacityAnimation 0.5s forwards;

    span {
      cursor: pointer;
      padding: .5rem;

      &:hover {
        background-color: #2a2c2d;
      }
    }
  }

  .qr-image-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex: 1;
    background: #101010bf;

    .qr-image {
      min-height: 50vh;
      min-width: 50vw;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .qr-config {
      margin-top: 1.5rem;
      display: grid;
      grid-template-areas: "label-quality . quality-select . icon . slider-bg";
      grid-template-columns: auto 0.5rem auto 8rem auto 0.5rem auto;

      label {
        grid-area: label-quality;
      }

      select {
        grid-area: quality-select;
        cursor: pointer;
      }

      span {
        grid-area: icon;
      }

      #slider-bg {
        grid-area: slider-bg;
        cursor: pointer;
      }
    }
  }
}

@keyframes show {
  0% {
    background: rgba(0, 0, 0, 0);
  }

  100% {
    background: rgba(0, 0, 0, 0.3);
  }
}

@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.catalog-item-qr {
  width: 12rem;
  height: 14rem;
  align-items: center;
  background-color: #495057;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 1px #495057;

  &:hover {
    box-shadow: 0px 0px 2px 4px #495057;
  }

  .catalog-item-description {
    color: #b0b4b7;
    font-size: 0.8rem;
  }

  .catalog-item-icon {
    font-size: 1.3rem;
    color: #b0b4b7;
    cursor: pointer;

    &:hover {
      color: white;
    }

    &.edit {
      font-size: 1.1rem;
      line-height: unset;
    }
  }

  .catalog-item-name {
    margin-left: 1.3rem;
  }

  .catalog-item-time {
    font-size: .6rem;
    letter-spacing: 2px;
    text-decoration: revert;
    color: #b0b4b7;
  }

  .catalog-item-preview {
    width: 124px;
    height: 124px;

    cursor: zoom-in;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    &:hover {
      mask-image: radial-gradient(rgba(100, 100, 100, 1), transparent);
      -webkit-mask-image: radial-gradient(rgba(100, 100, 100, 1), transparent);
    }
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

#qr-modal {
  form {
    ::placeholder {
      font-style: italic;
    }
  }
}