
// 50 Shades Definitions //

$secondary-10: rgba(100,108,119,0.1);
$secondary-20: rgba(100,108,119,0.2);
$secondary-40: rgba(100,108,119,0.4);
$secondary-70: rgba(100,108,119,0.7);

// Black Opacities

$black-700: rgba(0, 0, 0, 0.07);
$black-10: rgba(0, 0, 0, 0.1);
$black-20: rgba(0, 0, 0, 0.2);
$black-70: rgba(0, 0, 0, 0.7);
$black-80: rgba(0, 0, 0, 0.8);

// White Opacities

$white-70: rgba(255,255,255,0.7);
$white-100: rgba(255,255,255,1);

$editor-body: #eceeef;
$gray-600: #646c77 ;
$dark-text:#495057;
$lightgrey-text: #ccd6dc;

$dark-bg: #161819;
$light-bg: #757e8c;

$light-blue: #cbdbef;

// Colour Definitions

$blue:    #0e73e6;
$indigo:  #6610f2  ;
$purple:  #6f42c1  ;
$pink:    #e83e8c  ;
$red:     #e53534  ;
$orange:  #ea7f59  ;
$yellow:  #ffc107  ;
$green:   #06b767  ;
$teal:    #20c997  ;
$cyan:    #17a2b8  ;
$dark-blue: #1458a5;
$dark-grey: #343a40;

// Brand Colours //

$theme-colors: (
    "primary":   $blue,
    "secondary":  $gray-600,
    "success":    $green,
    "yellow": $yellow,
    "warning":    $orange,
    "danger":     $red,
    "indigo": $indigo

  );

  
// Tabs //

$nav-tabs-link-active-border-color: none;

.nav-link {border: none;}

// Margins

$primary-nav-margin: 0.8rem;


// Sidebar

$sidebar-width:40px;

// Item Radius
$item-radius: 3px;

.opacity-2 {opacity:0.1 !important;}

// Flex

.flex-grow-2 {
  flex-grow:2;
}

// Text

.white-header {
  background: $white-100;
  border-radius: 5px 5px 0px 0px;
}

.dark-text {
  color: $dark-text !important;
}

.opacity-2 {opacity:0.1 !important;}

$rad: 3.5px;