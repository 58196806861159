#avatar-container {

    #RenderCanvas {
        width: 90%;
        height: 90%;
        position: inherit;
        bottom: unset;
        right: unset;
        top: unset;
        left: unset;
        outline: none;
        -webkit-tap-highlight-color: transparent;
    }

    .avatar-create {
        display: grid;
        grid-template-columns: 35rem 1px auto;
        grid-template-areas: 'edit separator preview';
        grid-gap: 3rem;

        .edit {
            display: flex;
            flex-direction: column;

            button{
                align-self: self-start;
                margin-bottom: 0.5rem;

                p {
                    width: 1.5rem;
                }
                
                span {
                    vertical-align: super;
                }
            }
        }

        .preview {
            grid-area: preview;

            .text-not-found,
            .icon-not-found {
                display: none;
            }
        }

        .not-found {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            color: #3d444a2e;

            .icon-not-found {
                display: block;
                font-size: 22rem;
            }

            .text-not-found {
                display: block;
                font-size: 2.5rem;
            }
        }

        .separator {
            grid-area: separator;
            height: 80%;
            background-color: rgba(0, 0, 0, 0.1);
        }

        .avatar-create-container {
            grid-area: buttons;
            display: grid;
            justify-content: space-between;
            grid-template-columns: repeat(3, 10rem);
            grid-template-areas: 'unlock face-3 face-5'
                'separator separator separator';
            grid-gap: 1rem;


            .face-unlock {
                grid-area: unlock;
            }

            .face-3 {
                grid-area: face-3;
            }

            .face-5 {
                grid-area: face-5;
            }

            button {
                height: 9rem;
                line-height: 2;
                border-radius: .5rem;

                &.btn-primary.active{
                    background-color: #495057;
                    border-color: #667587;
                }


                &.btn-primary:not(.active){
                    background-color: #6b7380;
                    border-color: #667587;
                    box-shadow: unset;
                }

                &.btn-primary:hover{
                    background-color: #495057;
                    border-color: #667587;
                    box-shadow: unset;
                }

                &.btn-primary:not(:disabled):not(.disabled).active {
                    background-color: #495057;
                    border-color: #667587;
                    box-shadow: unset;

                    &:focus {
                        box-shadow: 0 0 0 0.2rem rgba(38, 38, 39, 0.25);
                    }
                }



                .icon-face {
                    font-size: 4rem;
                }
            }

            hr {
                grid-area: separator;
                width: 90%;
            }
        }

        .upload-container {
            display: flex;
            flex-direction: column;

                .logo-container {
                    padding: 0.25rem;
                    height: 10rem;
                    border-radius: 5px;
                
                    .drop-zone {
                      &.link-not-empty{
                          -webkit-box-shadow: 0px -1px 6px 1px rgb(0 0 0 / 18%);
                          box-shadow: 0px -1px 6px 1px rgb(0 0 0 / 18%);
                          padding: unset;
                          border: none;
                      }
                      height: 100%;
                      padding: 0.25rem;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      text-align: center;
                      font-family: "Quicksand", sans-serif;
                      font-weight: 500;
                      font-size: 1.25rem;
                      cursor: unset;
                      color: #cccccc;
                      border: 3px dashed #4950575e;
                      border-radius: 2px;
                
                      .drop-zone--over {
                        border-style: solid;
                      }
                
                      .drop-zone__input {
                        display: none;
                      }
                
                      .drop-zone__thumb {
                        width: 100%;
                        height: 100%;
                        border-radius: 2px;
                        overflow: hidden;
                        background-color: #cccccc;
                        background-size: contain;
                        background-position: center center;
                        background-repeat: no-repeat;
                        position: relative;
                      }
                
                      .drop-zone__thumb:hover::after {
                        content: attr(data-label);
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        color: #ffffff;
                        background: #7e7e7eeb;
                        font-size: 0.7rem;
                        text-align: center;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        padding: 0.25rem 0.5rem;
                        letter-spacing: 1px;
                      }
                    }
                  }
        }   

        .upload{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #59606a;
            border-color: #667587;
            
            &:focus{
                box-shadow: 0 0 0 0.2rem rgba(38, 38, 39, 0.25);
            }

            &:not(:disabled):not(.disabled):hover{
                background-color: #495057;
                border-color: #667587;
                
            }
        }
    }
    
    #readyPlayerMe-iframe-container{
        width: 90%;
        height: 90%;
        display: flex;
        position: fixed;
        flex-direction: column;
        background-color: #ffffff;

        #readyPlayerMe-iframe-header{
            width: 100%;
            background-color: var(--secondary);
            text-align: right;
            display: flex;
            justify-content: flex-end;

            #close-iframe-button{
                background: none;
                border: 0;
                width: 1.5rem;
                padding: 0;
                margin: 0.3rem;
                align-items: center;
                box-shadow: none !important;

                &>svg {
                    width: 100%;
                    line {
                    stroke: #FFFFFF;
                    }
                }
                
                &>svg:hover,
                &:hover>svg {
                    line {
                    stroke: #DC4C4C;
                    }
                }
                
                &>svg.active,
                &.active>svg,
                &>svg:active,
                &:active>svg {
                    line {
                    stroke: #DC4C4C;
                    }
                }
                
                &>svg:disabled,
                &:disabled>svg {
                    line {
                    stroke: #c6c6c6;
                    }
                }
            }
        }

        #readyPlayerMe-iframe{
            flex-grow: 1;
            border: none;
        }
    }

    .avatar-button{
        box-shadow: none !important;
        border-color: #ffffff;
        display: flex;
        color: #ffffff;
        background-color: none;

        &>svg {
            ellipse {
                fill: none;
            }

            path{
                fill:none;
                &:last-of-type {
                    fill: #ffffff;
                }
            }
        }

        &:hover{
            border-color: #d9d9d9;
            color: #d9d9d9;
            &>svg {
                path:last-of-type {
                    fill: #d9d9d9;
                }
            }
        }

        &:active{
            border-color: #8ec47b;
            background-color: #8ec47b;
            color: #444444;
            &>svg {
                path:last-of-type {
                    fill: #444444;
                }
            }
        }
    }

    #switch-avatar, #regenerate-avatar{
        width: 12rem;
    }
}