@import "./custom_variables.scss";
@import "bootstrap/scss/bootstrap.scss";

* {
  font-family: Lato, sans-serif;
}

$rad: 3.5px;

#RenderCanvas {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.z-position-closer{
  z-index: 3;
}
.z-position-close{
  z-index: 2;
}

p {
  margin-bottom: 0px !important;
}

body,
html {
  height: 100%;
  width: 100%;
  // background-color: #1f1f20;
}

.hidden {
  display: none !important;
}

#graph-container {
  height: 100%;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: Lato, sans-serif;
}

.dropdown-item {
  cursor: pointer;
}

.whitespace-normal {
  white-space: normal;
}
.whitespace-nowrap {
  white-space: nowrap;
}

.flex-center {
  display: flex;
  align-content: center;
  justify-content: center;
}

.w-100 {
  width: 100%;
}
.align-items-center {
  align-items: center;
}

.material-icons-outlined {
  user-select: none;
}

// nav {
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   height: 50px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   background-color: dimgrey;
//   & > button {
//     height: 30px;
//     background-color: lightgray;
//   }
// }

// .userIcon {
//   width: 40px;
//   height: 40px;
//   border-radius: 20px;
//   position: absolute;
//   right: 5px;
//   top: 5px;
// }
@media only screen and (max-width: 1080px) {
  .consoleContainer {
    display: none;
  }
}
.consoleContainer {
  border-radius: 6px;
  position: absolute;
  right: 30px;
  top: 65px;
  width: 500px;
  height: 500px;
  background-color: rgba(0, 0, 0, 0.6);
  & > #console {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 486px;
    padding: 7px;
    & > pre {
      display: block;
      color: whitesmoke;
      font-family: "Courier New", Courier, monospace;
      font-size: 0.6rem;
      max-width: 100%;
      &.error {
        color: red;
        & > i.count {
          background-color: red;
          color: whitesmoke;
        }
      }
      & > i.count {
        background-color: #0099ff;
        padding: 3px 7px;
        border-radius: 0.5rem;
        display: inline-block;
        font-weight: bold;
      }
    }
  }
}

.interactable {
  cursor: pointer;
}

.pointer-disable {
  pointer-events: none;
}

.pointer-enable {
  pointer-events: auto;
}

.disabled {
  pointer-events: none;
  user-select: none;
}

.scrollable-list-group {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.main-fluid {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow: hidden;
}

.sidebar-right-el{
  box-shadow: 3px -1px 9px 0 #000000;
  border-left: 1px solid #505050;
}

.h-30vh {
  height: 30vh;
}
.h-55vh {
  height: 55vh;
}

// Scrollbar

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: $blue;
}
::-webkit-scrollbar-thumb:hover {
  background: $blue;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

// alerts

.alert-wrapper {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: 1000;
}

.alert {
  background: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  width: 300px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  margin: 10px;
}

.alert-text {
  text-align: center;
  font-size: 1.2rem;
  color: $secondary;
  padding: 1rem;
}

.alert-active {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

//GLTF Hierarchy //

.gltf-hierarchy-panel {
  background: $editor-body;
  width: 300px;
}

.mesh-list {
  padding-left: 0px;
}
.mesh-list:hover {
  background: rgba(14, 115, 230, 0.1);
}

.nested {
  background: $editor-body;
  margin: 0;
  padding: 0px;
}

.nested-lower {
  background: $editor-body;
  margin: 0;
  padding: 0px;
}

.nested-lower > .list-item {
  padding-left: 30px;
}

.caret {
  display: block;
  line-height: 1.5rem;
  padding: 10px;
  padding-left: 20px !important;
  //padding-left:40px;
  cursor: pointer;
  //border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  align-items: center;
}

.caret:before {
  color: $secondary;
}

.top-caret {
  padding-left: 10px !important;
  display: flex;
  align-items: center;
}

.list-item {
  padding: 3px 10px;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  background: rgba(14, 115, 230, 0.1);
  border-top: 1px solid white;
}

.overflow-y-auto{
  overflow-y: auto;
}

.mesh-list{
  .list-item{
    &.selected-list-item {
      height: 4rem;

      .list-img{
        height: 3rem;
      }
      .list-item-more-options {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.list-item-more-options {
  font-size: 0.6rem;
  height: 100%;
  width: 3rem;
  color: #ffffff;
  display: none;

  .list-item-more-options-text::after {
    letter-spacing: 1px;
    content: "•••";
  }
}

.list-icon-selected {
  font-size: 9rem;
}

.list-text {
  flex:auto;
  margin-left: 0.5rem;
  padding-right: 8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-img {
  height: 1.2rem;
  margin-right: 5px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

// Modals

.modal-wrapper {
  position: absolute;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
}

.warning-modal {
  background: white;
  height: fit-content;
  padding: 1.2rem 3rem;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 20px;
}

.modal-title {
  text-align: center;
  font-size: 1.3rem;
  color: $dark-text;
}

.modal-text {
  width: 100%;
  text-align: center;
  color: $secondary;
}

.modal-button-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
}

.modal-button {
  margin: 5px;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.material-breadcrumb {
  & > .breadcrumb-item {
    // outline: 2px dashed grey;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0rem;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
    border-radius: 3px;
  }
}

.breadcrumb-droppable {
  & > .breadcrumb-item {
    & > a {
      outline: 2px dashed grey;
      border-radius: 3px;
      outline-offset: 0.4rem;
    }

    &+.breadcrumb-item::before {
      opacity: 0;
    }
  }
}

.custom-swal2-progress-header .swal2-progress-steps {
  width: 80%;
  margin-bottom: 3rem;
}

.custom-swal2-progress-header .swal2-progress-step-line {
  flex-grow: 1;
}

.custom-swal2-progress-header .swal2-progress-step {
  color: black !important;
  overflow: visible;
  display: flex;
  justify-content: center;
  // min-width: -moz-fit-content;
  // min-width: -webkit-fill-available;
  // min-width: fit-content;
  padding: 0 0.5rem;
}

.custom-swal2-actions{
  .swal2-cancel{
    background-color: #d14529 !important;
  }

  .swal2-deny{
    background-color: #646c77 !important;
  }

  justify-content: flex-end !important;
}

.custom-swal2-actions-flex{
  .swal2-cancel{
    background-color: #d14529 !important;
  }

  .swal2-deny{
    background-color: #646c77 !important;
  }

  display: flex !important;
  justify-content: flex-end !important;
}
